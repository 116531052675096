import React from "react"
import styled from "styled-components"

const CopyLine = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Footer = props => {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <CopyLine>
          Handcrafted by me <span className="is-size-3 px-1">©</span>
          twentytwenty
        </CopyLine>
      </div>
    </footer>
  )
}

export default Footer
