import React from "react"
import NavBar from "./NavBar"
import "../styles/bulma.scss"
import Footer from "./Footer"

const Layout = props => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  const [scrolled, setScrolled] = React.useState(false)

  const updateScrolled = () => setScrolled(window.scrollY > 64)

  React.useEffect(() => {
    window.addEventListener("scroll", updateScrolled)

    return () => {
      window.removeEventListener("scroll", updateScrolled)
    }
  }, [])

  return (
    <div>
      <NavBar scrolled={scrolled} />
      <main>{props.children}</main>
      <Footer />
    </div>
  )
}

export default Layout
