import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"

const StyledLink = styled(props => <Link {...props} />)`
  font-weight: 600;
  font-size: 24px;
`

const NavBar = props => {
  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => setOpen(!open), [open])
  const navRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (open && navRef.current && !navRef.current.contains(event.target)) {
        toggleOpen()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [navRef, open, toggleOpen])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setOpen(false)
    })
  }, [setOpen])

  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      ref={navRef}
      style={{
        boxShadow: props.scrolled ? "0 8px 16px rgba(10, 10, 10, 0.1)" : null,
      }}
    >
      <div className="container">
        <div className="navbar-brand">
          <StyledLink className="navbar-item" to="/">
            Dávid Lándori
          </StyledLink>
          <a
            role="button"
            onClick={toggleOpen}
            className={`navbar-burger burger ${open ? "is-active" : null}`}
            aria-label="menu"
            aria-expanded={open}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${open ? "is-active" : null}`}>
          <div className="navbar-end">
            <Link className="navbar-item" to="/portfolio">
              Porfolio
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

NavBar.propTypes = {
  scrolled: PropTypes.bool,
}

export default NavBar
